var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMeasurementEnabled)?_c('div',{staticClass:"modal__block"},[_c('div',{staticClass:"modal__section"},[_c('div',{staticClass:"modal__sign sign"},[_c('div',{staticClass:"sign__icon"},[_c('micon',{attrs:{"name":"insert_chart_outlined"}})],1),_c('h3',{staticClass:"sign__title"},[_vm._v("Nivel Alerta")])]),_c('article',{staticClass:"modal__fields"},[(_vm.measurementType === 'default')?_c('div',{staticClass:"range"},[_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("Nivel alerta:")]),_c('el-select',{model:{value:(_vm.model.completedMeasurement),callback:function ($$v) {_vm.$set(_vm.model, "completedMeasurement", $$v)},expression:"model.completedMeasurement"}},_vm._l((_vm.measurements),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.text}})}),1)],1)])]):_vm._e(),(_vm.measurementType === 'simple-range')?_c('div',{staticClass:"range"},[_vm._m(0),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto:")]),_c('ValidationProvider',{attrs:{"name":"Correcto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.green),callback:function ($$v) {_vm.$set(_vm.model.measurement, "green", $$v)},expression:"model.measurement.green"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2249602339)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable:")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable","rules":{required: true, min_value: _vm.model.measurement.green}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.yellow),callback:function ($$v) {_vm.$set(_vm.model.measurement, "yellow", $$v)},expression:"model.measurement.yellow"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2105917180)})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal:")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal","rules":{required: true, min_value: _vm.model.measurement.yellow}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.red),callback:function ($$v) {_vm.$set(_vm.model.measurement, "red", $$v)},expression:"model.measurement.red"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4161941355)})],1)])]):_vm._e(),(_vm.measurementType === 'min-max-range')?_c('div',{staticClass:"range"},[_vm._m(1),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.model.measurement.green.min),callback:function ($$v) {_vm.$set(_vm.model.measurement.green, "min", $$v)},expression:"model.measurement.green.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1786193501)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Correcto Máximo")]),_c('ValidationProvider',{attrs:{"name":"Correcto Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.green.max),callback:function ($$v) {_vm.$set(_vm.model.measurement.green, "max", $$v)},expression:"model.measurement.green.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1737167343)})],1)]),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.yellow.min),callback:function ($$v) {_vm.$set(_vm.model.measurement.yellow, "min", $$v)},expression:"model.measurement.yellow.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,495532622)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Tolerable Máximo")]),_c('ValidationProvider',{attrs:{"name":"Desviación tolerable Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.yellow.max),callback:function ($$v) {_vm.$set(_vm.model.measurement.yellow, "max", $$v)},expression:"model.measurement.yellow.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3763288208)})],1)]),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Mínimo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Min","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.red.min),callback:function ($$v) {_vm.$set(_vm.model.measurement.red, "min", $$v)},expression:"model.measurement.red.min"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4046262393)})],1),_c('fieldset',{staticClass:"modal__field"},[_c('label',{staticClass:"label"},[_vm._v("* Anormal Máximo")]),_c('ValidationProvider',{attrs:{"name":"Desviación anormal Max","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.model.measurement.red.max),callback:function ($$v) {_vm.$set(_vm.model.measurement.red, "max", $$v)},expression:"model.measurement.red.max"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,735610407)})],1)])]):_vm._e()]),_c('article',{staticClass:"modal__fields"})])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("* Rangos de valores en horas:")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_c('b',[_vm._v("* Rangos de valores:")])])])])}]

export { render, staticRenderFns }