<script>
import model from "@/mixins/model";

export default {
  name: "TaskMeasurement",

  mixins: [model],

  data() {
    return {
      measurements: [
        { value: "green", text: "Verde" },
        { value: "yellow", text: "Amarillo" },
        { value: "red", text: "Rojo" },
      ],
    };
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    isMeasurementEnabled() {
      return (
        // Checkins have it's own measurement form
        this.type === "reports" ||
        this.type === "exercises" ||
        this.type === "medications" ||
        this.type === "recommendations" ||
        this.type === "vitals"
      );
    },
    measurementType() {
      switch (this.type) {
        case "exercises":
        case "medications":
        case "recommendations":
          return "simple-range";

        case "vitals":
          return "min-max-range";

        default:
          return "default";
      }
    },
  },
};
</script>

<template lang="pug">
  .modal__block(v-if="isMeasurementEnabled")
    .modal__section
      .modal__sign.sign
        .sign__icon
          micon(name="insert_chart_outlined")
        h3.sign__title Nivel Alerta
      article.modal__fields
        .range(v-if="measurementType === 'default'")
          .modal__row
            fieldset.modal__field
              label.label Nivel alerta:
              el-select(v-model="model.completedMeasurement")
                el-option(
                  v-for="option in measurements"
                  :value="option.value"
                  :key="option.value"
                  :label="option.text"
                )

        .range(v-if="measurementType === 'simple-range'")
          .modal__row
            fieldset.detail__field
              label.label
                b * Rangos de valores en horas:
          .modal__row
            fieldset.detail__field
              label.label * Correcto:
              ValidationProvider(name="Correcto", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.green"
                  type="number"
                  step="any"
                  :class="{'has-error': errors[0]}"
                )
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Tolerable:
              ValidationProvider(name="Desviación tolerable", :rules="{required: true, min_value: model.measurement.green}", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.yellow"
                  type="number"
                  step="any"
                  :class="{'has-error': errors[0]}"
                )
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Anormal:
              ValidationProvider(name="Desviación anormal", :rules="{required: true, min_value: model.measurement.yellow}", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.red"
                  type="number"
                  step="any"
                  :class="{'has-error': errors[0]}"
                )
                span.has-error {{ errors[0] }}

        .range(v-if="measurementType === 'min-max-range'")
          .modal__row
            fieldset.detail__field
              label.label
                b * Rangos de valores:
          .modal__row
            fieldset.modal__field
              label.label * Correcto Mínimo
              ValidationProvider(name="Correcto Min", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.green.min"
                  type="number"
                  step="any"
                  min="0"
                )
                span.has-error {{ errors[0] }}

            fieldset.modal__field
              label.label * Correcto Máximo
              ValidationProvider(name="Correcto Max", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.green.max"
                  type="number"
                  step="any"
                )
                span.has-error {{ errors[0] }}

          .modal__row
            fieldset.modal__field
              label.label * Tolerable Mínimo
              ValidationProvider(name="Desviación tolerable Min", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.yellow.min"
                  type="number"
                  step="any"
                )
                span.has-error {{ errors[0] }}

            fieldset.modal__field
              label.label * Tolerable Máximo
              ValidationProvider(name="Desviación tolerable Max", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.yellow.max"
                  type="number"
                  step="any"
                )
                span.has-error {{ errors[0] }}

          .modal__row
            fieldset.modal__field
              label.label * Anormal Mínimo
              ValidationProvider(name="Desviación anormal Min", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.red.min"
                  type="number"
                  step="any"
                )
                span.has-error {{ errors[0] }}

            fieldset.modal__field
              label.label * Anormal Máximo
              ValidationProvider(name="Desviación anormal Max", rules="required", v-slot="{ errors }")
                el-input(
                  v-model="model.measurement.red.max"
                  type="number"
                  step="any"
                )
                span.has-error {{ errors[0] }}

      article.modal__fields
</template>
